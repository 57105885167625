<main class="managers-details-main p-3">
  <div class="back d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center pointer" (click)="backToListingsPage()">
      <svg width="22" height="22" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
        <path fill-rule="evenodd"
          d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
      </svg>
      <!-- <p class="fw600 fs18 ml-3 page-head">{{titleName}} {{trName}} ({{brand_IJPJobId}}) </p> -->
      <p class="fw600 fs18 ml-3 page-head">{{titleName}} ({{brand_IJPJobId}}) </p>
      <p></p>
    </div>

    <div class="approve-reject d-flex justify-content-end"
      *ngIf="tabsStoredValue != 'all' && ijpJobApprovedStatus!='Closed'">

      <button *ngIf="enableApproveReject" type="button" class="blue-btn" (click)="handleApprove(lastSegment)" pRipple>
        Approve
      </button>
      <button *ngIf="enableApproveReject" type="button" class="reject-button" (click)="showDialog()">
        Reject
      </button>
      <button *ngIf="enableReactivate" type="button" class="blue-btn " (click)="reactivatejob(lastSegment)">
        Reactivate
      </button>
    </div>
  </div>

  <section class="details-basic-info-part">
    <div class="top-part d-flex justify-content-between">
      <h1 class="fw600 fs16 mb-3">Basic Info</h1>
    </div>
    <div class="line my-0"></div>
    <h4 class="fw700 fs16 mt-4">Job Information</h4>
    <div class="basic-info-parts my-new-basic-info-parts py-3">
      <div class="field-part">
        <h4 class="fw600">TRA ID</h4>
        <h3>{{ trName }}</h3>
      </div>
      <div class="field-part">
        <h4 class="fw600">Job Id</h4>
        <h3>{{ brand_IJPJobId }}</h3>
      </div>
      <div class="field-part">
        <h4 class="fw600">School</h4>
        <h3>{{ brandName }}</h3>
      </div>
      <div class="field-part">
        <h4 class="fw600">Job Title</h4>
        <h3>{{ titleName }}</h3>
      </div>
      <div class="field-part">
        <h4 class="fw600">Country</h4>
        <h3>{{ countryName }}</h3>
      </div>
      <div class="field-part">
        <h4 class="fw600">Campus</h4>
        <h3>{{ campusName }}</h3>
        <!-- <h3 *ngFor="let x of commaSeperated(trdata?.campusNames)">{{ x }}</h3> -->
      </div>
      <div class="field-part">
        <h4 class="fw600">Department</h4>
        <h3>{{ department }}</h3>
      </div>
      <div class="field-part">
        <h4 class="fw600">Recruiter</h4>
        <h3>{{ recruitmentmanagername }}</h3>
      </div>
      <div class="field-part">
        <h4 class="fw600">Number of Positions</h4>
        <h3>{{ numberOfPositions }}</h3>
      </div>
      <div class="field-part">
        <h4 class="fw600">Job Category</h4>
        <h3>{{ jobCategoryname }}</h3>
      </div>
      <div class="field-part">
        <h4 class="fw600">Priority</h4>
        <h3>{{ priority || '--' }}</h3>
      </div>
    </div>

    <!-- <div class="basic-info-parts my-new-basic-info-parts py-3">

    </div> -->

    <div class="my-new-basic-info-parts py-3">
      <div class="field-part mb-4">
        <h4 class="fw600">Type of Hiring</h4>
        <h4 class="fs14 fw400">{{ basicInfoData?.typeOfHiring }}</h4>
      </div>

      <div class="field-part ">
        <h4 class="fw600">Type of Employment</h4>
        <h3 class="fs14 fw400">{{ basicInfoData?.typeofEmployment }}</h3>
      </div>
      <div class="field-part ">
        <h4 class="fw600">Rational for Hiring Non-Budgeted position</h4>
        <h3 class="fs14 fw400" [innerHTML]="basicInfoData?.rationalForHiringNonBudgetedPosition"></h3>
      </div>



    </div>

    <!-- <h4 class="fw700 fs16 mt-4">Type of Hiring</h4> -->
    <!-- <div class="basic-info-parts my-new-basic-info-parts py-3">
      <div class="field-part">
        <h4 class="fw600">Type of Hiring</h4>
        <h3>{{ basicInfoData?.typeOfHiring }}</h3>
      </div>
      <div class="field-part pe-1">
        <h4 class="fw600">Rational for Hiring Non-Budgeted position</h4>
        <h3 [innerHTML]="basicInfoData?.rationalForHiringNonBudgetedPosition"></h3>
      </div>
      <div class="field-part">
        <h4 class="fw600">Type of Employment</h4>
        <h3>{{ basicInfoData?.typeofEmployment }}</h3>
      </div>
    </div> -->

    <h4 class="fw700 fs16 mt-4">Employment Related</h4>
    <div class="basic-info-parts my-new-basic-info-parts py-3">
      <div class="field-part">
        <h4 class="fw600">Employment Duration (In Days)</h4>
        <h3>{{ basicInfoData?.employmentDuration }}</h3>
      </div>
      <div class="field-part">
        <h4 class="fw600">Expected Joining Date</h4>
        <h3>{{ basicInfoData?.expectedJoiningDate | date }}</h3>
      </div>
      <div class="field-part">
        <h4 class="fw600">Type of Appointment</h4>
        <h3>{{ basicInfoData?.typeofEmployment }}</h3>
      </div>
      <!-- <div class="field-part">
        <h4 class="fw600">Salary Offered Range Code</h4>
        <h3>{{ basicInfoData?.currencySymbol }}</h3>
      </div> -->
      <!-- <div class="field-part">
        <h4 class="fw600">Salary Offered Range From (In Annual)</h4>
        <h3>{{ basicInfoData?.expectedSalaryFrom }}</h3>
      </div> -->
      <!-- <div class="field-part">
        <h4 class="fw600">Salary Offered Range To (In Annual)</h4>
        <h3>{{ basicInfoData?.expectedSalaryTo }}</h3>
      </div> -->

      <!-- <div class="field-part">
        <h4 class="fw600">Hiring Process Cutoff Date</h4>
        <h3>{{ basicInfoData?.cutoffDate | date }}</h3>
      </div> -->
    </div>



<!--
    <h4 class="fw700 fs16 mt-4">Post Job To</h4>
    <div class="basic-info-parts my-new-basic-info-parts py-3"
      *ngFor="let m of basicInfoData?.addjobsDatesArray1; let n = index">
      <div class="field-part">
        <h4 class="fw600" *ngIf="n == 0">Post Job to</h4>
        <h3>{{ m?.postjobto }}</h3>
      </div>
      <div class="field-part">
        <h4 class="fw600" *ngIf="n == 0">Publish Start Date</h4>
        <h3>{{ m.publishstartdate | date }}</h3>
      </div>
      <div class="field-part">
        <h4 class="fw600" *ngIf="n == 0">Publish End Date</h4>
        <h3>{{ m.publishenddate | date }}</h3>
      </div>
    </div> -->

    <!-- <h4 class="fw700 fs16 mt-4">Hiring SPOC’s</h4> -->
    <div class="basic-info-parts my-new-basic-info-parts py-3">
      <!-- <div class="field-part">
        <h4 class="fw600">Hiring Manager</h4>
        <h3>{{ basicInfoData?.hiringManagerId }}</h3>
      </div>
      <div class="field-part">
        <h4 class="fw600">Recruitment Manager</h4>
        <h3>{{ recruitmentmanagername }}</h3>
      </div> -->
      <!-- <div class="field-part">
        <h4 class="fw600">Business Partner</h4>
        <h3>{{ businesspartnername }}</h3>
      </div> -->
    </div>

    <h4 class="fw700 fs16 mt-4">Applicant Eligibility Crieteria</h4>
    <div class="basic-info-parts my-new-basic-info-parts py-3"
      *ngFor="let m of basicInfoData?.preferredIndustries; let n = index">
      <div class="field-part">
        <h4 class="fw600" *ngIf="n == 0">Preferred Industry</h4>
        <h3>{{ m?.prefferedIndustry }}</h3>
      </div>
      <div class="field-part">
        <h4 class="fw600" *ngIf="n == 0">Industry Experience (In Years)</h4>
        <h3>{{ m.prefferedIndustryExperienceFrom }} to {{ m.prefferedIndustryExperienceTo }}</h3>
      </div>
      <!-- <div class="basic-info-parts py-3 d-flex" *ngFor="let m of domaindetail; let n = index"> -->
    </div>
    <div class="basic-info-parts my-new-basic-info-parts py-3">
      <ng-container *ngFor="let m of basicInfoData?.preferredDomains; let n = index">
        <div class="field-part">
          <h4 class="fw600" *ngIf="n == 0">Preferred Domain</h4>
          <h3>{{ m.preferedDomain }}</h3>
        </div>
        <div class="field-part">
          <h4 class="fw600" *ngIf="n == 0">Domain Experience (In Years)</h4>
          <h3>{{ m.domainExperienceFrom }} to {{ m.domainExperienceTo }}</h3>
        </div>
        <div class="field-part">
          <h4 class="fw600">Total Experience (In Years)</h4>
          <h3> {{ basicInfoData?.totalExperienceFrom }} to {{ basicInfoData?.totalExperienceTo }} </h3>
        </div>
      </ng-container>
    </div>

    <div class="basic-info-parts my-new-basic-info-parts py-3">
      <div class="field-part">
        <h4 class="fw600">Current Salary Currency Code </h4>
        <h3>{{ basicInfoData?.currencySymbol }}</h3>
      </div>
      <div class="field-part">
        <h4 class="fw600">Current Salary Range From (Annual)</h4>
        <h3>{{ basicInfoData?.currenctSalaryFrom }}</h3>
      </div>
      <div class="field-part">
        <h4 class="fw600">Current Salary Range To (Annual)</h4>
        <h3>{{ basicInfoData?.currenctSalaryTo }}</h3>
      </div>
    </div>


     <div class="basic-info-parts my-new-basic-info-parts py-3">
      <div class="field-part">
        <h4 class="fw600"> Offered Salary Currency Code </h4>
        <h3>{{ basicInfoData?.expectedSalarySymbol }}</h3>
      </div>
      <div class="field-part">
        <h4 class="fw600"> Salary Offer Range From (Annual)</h4>
        <h3>{{ basicInfoData?.expectedSalaryFrom }}</h3>
      </div>
      <div class="field-part">
        <h4 class="fw600"> Salary Offer Range To (Annual)</h4>
        <h3>{{ basicInfoData?.expectedSalaryTo }}</h3>
      </div>
    </div>

      <div class="basic-info-parts my-new-basic-info-parts py-3">
      <div class="field-part">
        <h4 class="fw600">Higher level of Education</h4>
        <h3>{{ basicInfoData?.higherLevelOfEducation }}</h3>
      </div>
      <div class="field-part">
        <h4 class="fw600">Qualifications</h4>
        <h3>{{ basicInfoData?.qualificationName }}</h3>
      </div>
      <div class="field-part">
        <h4 class="fw600">Languages Known</h4>
        <h3>{{ basicInfoData?.languagesKnownName }}</h3>
      </div>
      <div class="field-part">
        <h4 class="fw600">Nationality</h4>
        <h3>{{ basicInfoData?.nationalityName }}</h3>
      </div>
    </div>

    <div class="basic-info-parts my-new-basic-info-parts py-3">
      <div class="field-part">
        <h4 class="fw600">Gender</h4>
        <h3>{{ basicInfoData?.preferredGender }}</h3>
      </div>
      <div class="field-part">
        <h4 class="fw600">Notice Period (In Days)</h4>
        <h3>{{ basicInfoData?.noticePeriodFrom }} to {{ basicInfoData?.noticePeriodTo }} </h3>
      </div>
      <!-- <div class="field-part">
        <h4 class="fw600">Time Needed to Relocate (In Days )</h4>
        <h3>{{ timeNeededToRelocate }}</h3>
      </div> -->
      <div class="field-part">
        <h4 class="fw600"> Minimum Age Bar (Above 18 years) </h4>
        <h3>{{ basicInfoData?.maximumAgeBarFrom }} to {{ basicInfoData?.maximumAgeBarTo }}</h3>
      </div>
    </div>

    <h4 class="fw600 fs16 mt-4">Required Documents</h4>
    <div class="basic-info-parts my-new-basic-info-parts py-3"
      *ngFor="let m of basicInfoData?.jobDocumentInfos; let n = index">
      <div class="field-part">
        <h4 class="fw600" *ngIf="n == 0">Document Type</h4>
        <h3>{{ m.documentTypeName }}</h3>
      </div>
      <div class="field-part">
        <h4 class="fw600" *ngIf="n == 0">Stage</h4>
        <h3>{{ m.stageName || "--" }}</h3>
      </div>
    </div>

    <h4 class="fw600 fs16 mt-4">Work Permit Related</h4>
    <div class="basic-info-parts my-new-basic-info-parts py-3">
      <div class="field-part">
        <h4 class="fw600">Work Permit</h4>
        <h3>{{ basicInfoData?.workPermit }}</h3>
      </div>
      <div class="field-part">
        <h4 class="fw600">Country</h4>
        <h3>{{ basicInfoData?.workPermitCountry }}</h3>
      </div>
      <div class="field-part">
        <h4 class="fw600">Work Permit Type</h4>
        <h3>{{ basicInfoData?.workPermitTypeName }}</h3>
      </div>
    </div>
  </section>

  <section class="details-basic-info-part job-description-part-main mt-3">
    <div class="top-part d-flex justify-content-between">
      <h1 class="fw600 fs16">Job Description</h1>
    </div>
    <div class="line my-3"></div>
    <div class="overview-part mt-2">
      <h1 class="fw600 fs14">Overview</h1>
      <p [innerHTML]="basicInfoData?.jobDescriptionDepartment?.overView"></p>
    </div>
    <div class="responsibilities-part mt-3">
      <h1 class="fw600 fs14">Responsibilities</h1>
      <ul class=" mt-2">
        <li [innerHTML]="basicInfoData?.jobDescriptionDepartment?.responsibility"></li>
      </ul>
    </div>

    <div class="responsibilities-part mt-3">
      <h1 class="fw600 fs14">Skills Description</h1>
      <ul class="mt-2">
        <li [innerHTML]="basicInfoData?.jobDescriptionDepartment?.skillsDescription"></li>
      </ul>
    </div>

    <div class="responsibilities-part mt-3">
      <h1 class="fw600 fs14">Website Short Description</h1>
      <ul class=" mt-2">
        <li [innerHTML]="basicInfoData?.jobDescriptionDepartment?.websiteDescription"></li>
      </ul>
    </div>

    <div class="responsibilities-part mt-3">
      <h1 class="fw600 fs14">Disclaimer</h1>
      <p class="fw400 fs16 mt-2" [innerHTML]="basicInfoData?.jobDescriptionDepartment?.disclaimer"></p>
    </div>

    <!-- <div class="gap-3" *ngFor="let m of ijpjobskill; let n = index">
      <div class="responsibilities-part mt-3" >
        <h1 class="fw600 fs14">Skills Category</h1>
          <p> {{m.skillCategory}} </p>
      </div>

      <div class="responsibilities-part mt-3" >
        <h1 class="fw600 fs14">Skills</h1>
          <p> {{m.skillsName}} </p>
      </div>
      </div> -->

    <!-- <div class="responsibilities-part mt-3">
        <h1 class="fw600 fs14">Skills Category</h1>
        <p>{{ skillCategories }}</p>
      </div> -->
    <div class="skill_category_table mt-3">
      <table class="table">
        <thead>
          <tr>
            <th>Skills Category</th>
            <th>Skills</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let xyz of basicInfoData?.jobDescriptionDepartment?.jobskill">
            <td>
              <!-- {{ skillCategories }} -->
              <div>
                {{ xyz.skillCategoryName }}
              </div>
            </td>
            <td>
              <div>
                {{ xyz.skillsName }}
              </div>
              <!-- {{ skills }} -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- <div class="responsibilities-part mt-3">
        <h1 class="fw600 fs14">Skills</h1>
        <p>{{ skills }}</p>
      </div> -->


    <!-- <div class="d-flex align-items-center gap-3">
    <div class="responsibilities-part mt-3" *ngFor="let m of documentTypedeatil; let n = index" >
      <h1 *ngIf="n==0" class="fw600 fs14">Skills Category</h1>
        <p> {{m.skillCategory}} </p>
    </div>

    <div class="responsibilities-part mt-3" >
      <h1 *ngIf="n==0" class="fw600 fs14">Skills</h1>
        <p> {{m.skillsName}} </p>
    </div> -->
    <!-- </div> -->
    <!-- <div
      class="basic-info-parts my-new-basic-info-parts py-3"
      *ngFor="let m of basicInfoData?.hiringApprover?.hiringTeam; let n = index"
    >
      <div class="field-part">
        <h4 *ngIf="n == 0">Skills Category</h4>
        <h3>{{ m.skillCategoryName }}</h3>
      </div>
      <div class="field-part">
        <h4 *ngIf="n == 0">Skills</h4>
        <h3>{{ m.skillsName || "--" }}</h3>
      </div>
    </div> -->
  </section>
  <!-- <section class="details-basic-info-part mt-3">
    <div class="top-part">
      <div
        class="d-md-flex col-md-12 figma__ng__select mt-3"
        > -->
  <!-- *ngFor="let item of TRFInfoData.jobskill; let i = index" -->
  <!-- <div
          class="col-md-6 col-xl-4 field pl-0 pr-4 figma-ng-select"
        >
          <label class="form-label-d new__input__label"
            >Skills Category<span class="mandiatory">*</span></label
          >

          <ng-select
            [items]="skill_list"
            bindLabel="skill_CategoryName"
            bindValue="skill_CategoryId"
            [(ngModel)]="skillCategory"
            name="trfSkillsCCAtegorry"
            #trfSkillsCCAtegorry="ngModel"
            placeholder="Select skills category"
            [multiple]="false" disabled
          >
          </ng-select>
        </div>
        <div
          class="col-md-6 col-xl-4 figma-ng-select ms-3"
        >
          <label class=" "
            >Skills<span class="mandiatory">*</span></label
          >
          <ng-select
            [items]=" skill_list"
            bindLabel="skillName"
            bindValue="skillCategoryId"
            [(ngModel)]="skills"
            name="trffSkiillss"
            #trffSkiillss="ngModel"
            placeholder="Select skills"
            [multiple]="true"
          disabled
          >
          </ng-select>
        </div>
      </div>
    </div>
  </section> -->

  <!-- <section class="details-basic-info-part approval-list-part-main mt-3" *ngIf="!isIjpEmployeeValue">
    <div class="top-part d-flex justify-content-between">
      <h1 class="fw600 fs16">Recruiting Members</h1>
    </div>
    <div class="line my-2"></div>

    <div class="approval-list-table-main position-relative">
      <div class="table-responsive" *ngIf="hiringdetail?.length">
        <table class="table">
          <thead>
            <tr>
              <th>
                S.No
              </th>
              <th class="pointer" (click)="sortTable('teamMember')">
                Member <img class="ml-1" src="./assets/Images/sort-icons.svg" alt="sort" />
              </th>
              <th class="pointer" colspan="3" (click)="sortTable('hiringStageName')">
                Hiring stage<img class="ml-1"
                  src="./assets/Images/sort-icons.svg"
                  alt="sort"
                />
              </th>
              <th class="pointer" (click)="sortTable('interviewTemplate')">
                Interview Template
                <img class="ml-1" src="./assets/Images/sort-icons.svg" alt="sort" />
              </th>
              <th class="pointer" (click)="sortTable('cutoffDate')">
                Cut-Off Date
                <img class="ml-1" src="./assets/Images/sort-icons.svg" alt="sort" />
              </th>
              <th class="pointer" (click)="sortTable('interviewType')">
                Interview Tye
                <img class="ml-1" src="./assets/Images/sort-icons.svg" alt="sort" />
              </th>
              <th class="pointer" (click)="sortTable('GroupDiscussion')">
                Group Discussion
                <img class="ml-1" src="./assets/Images/sort-icons.svg" alt="sort" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let m of hiringdetail; let n = index">
              <td>
                <div class="d-flex justify-content-center">
                  {{ n + 1 }}
                </div>
              </td>
              <td>{{ m.teamMember }}</td>
              <td colspan="3">
                <div [ngClass]="{'justify-content-center': m.hiringStageName == null}">
                {{ m.hiringStageName }}
              </div> </td>
              <td>
                <div class="d-flex justify-content-center" [ngClass]="{'justify-content-center': m.interviewTemplateName == null}">
                  {{ m.interviewTemplateName || '--' }}
                </div>
              </td>
              <td>
                <div [ngClass]="{'justify-content-center': m.cutoffDate == null}">{{ m.cutoffDate | date }} </div> </td>
              <td> <p class="d-flex" [ngClass]="{'justify-content-center': m.interviewType == null}"> {{ m.interviewType || '--' }} </p> </td>
              <td>
                <p class="d-flex" [ngClass]="{'justify-content-center': m.groupDiscussion == null}"> {{ m.groupDiscussion || '--' }} </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p *ngIf="!hiringdetail?.length" class="fw600 width-max blue-color no-recruitment-members fs12 py-2">
         No Recruitment Members Added Yet </p>
    </div>
  </section> -->

  <section class="details-basic-info-part approval-list-part-main mt-3" *ngIf="!isIjpEmployeeValue">
    <div class="top-part d-flex justify-content-between">
      <h1 class="fw600 fs16">Internal Job Posting</h1>
    </div>
    <div class="line my-2"></div>
    <h4 class="fw600 fs16 mt-4">Eligible Brand</h4>
    <div class="basic-info-parts my-new-basic-info-parts py-2">
      <div class="field-part internal_fields" *ngFor="let m of targetdetail; let n = index">
        <h4 class="fw600">{{ m.targetBrandName }}</h4>
        <div class="d-flex label_span">

        </div>
      </div>
    </div>

    <h4 class="fw600 fs16 mt-4">Eligible Country</h4>
    <div class="basic-info-parts my-new-basic-info-parts py-2">
      <div class="field-part" *ngFor="let m of targetdetail; let n = index">
        <h4 class="fw600">{{ m.targetCountryName }}</h4>
        <div class="d-flex label_span">
          <!-- <span>TRA-1234</span>
          <span>TRA-1234</span>
          <span>TRA-1234</span>
          <span>+2</span> -->
        </div>
      </div>
    </div>

    <h4 class="fw600 fs16 mt-4">Eligible Campus</h4>
    <div class="basic-info-parts my-new-basic-info-parts py-2">
      <div class="field-part" *ngFor="let m of targetdetail; let n = index">
        <h4 class="fw600">{{ m.targetCampusName }}</h4>
        <div class="d-flex label_span">
          <!-- <span>TRA-1234</span>
          <span>TRA-1234</span>
          <span>TRA-1234</span>
          <span>+2</span> -->
        </div>
      </div>
    </div>

    <!-- <h4 class="fw600 fs16 mt-4">Eligible Department</h4>
    <div class="basic-info-parts my-new-basic-info-parts py-2">
      <div class="field-part" *ngFor="let m of targetdetail; let n = index">
        <h4 class="fw600">{{ m.targetDepartmentName }}</h4>
        <div class="d-flex label_span">

        </div>
      </div>
    </div> -->
  </section>

  <div class="pt-5">
    <p class="fw400 fs14">
      Does this job require approval from Applicant Reporting Manager?
    </p>
    <p class="fw600 fs16 mt-2">Yes</p>
  </div>

  <!-- <div class="line my-2"></div> -->

  <section class="details-basic-info-part approval-list-part-main mt-3" *ngIf="!isIjpEmployeeValue">
    <div class="top-part d-flex justify-content-between">
      <h1 class="fw600 fs16">Program Approvers</h1>
    </div>
    <div class="line my-2"></div>

    <div class="approval-list-table-main">
      <div class="position-relative">
        <table class="table" *ngIf="approverdetail?.length">
          <thead>
            <tr>
              <th class="pointer" (click)="sortTablee('levels')">
                Level
                <img src="./assets/Images/sort-icons.svg" alt="sort" />
              </th>
              <th class="pointer" (click)="sortTablee('role')">
                Approvers Designation
                <img src="./assets/Images/sort-icons.svg" alt="sort" />
              </th>
              <th class="pointer" colspan="3" (click)="sortTablee('approveMember')">
                Members Name / Email Id
                <img src="./assets/Images/sort-icons.svg" alt="sort" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let m of approverdetail; let n = index">
              <td class="pl-4">{{ m.levels }}</td>
              <td>{{ m.roleName }}</td>
              <td colspan="3">
                <div class="mt-1">
                  <div *ngFor="let xyz of commaSeperated(m.approveMember)">
                    {{ xyz }}
                  </div>

                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <p *ngIf="!approverdetail?.length" class="fw600 width-max blue-color no-recruitment-members my-3">
          No Program Approvers Added Yet </p>
      </div>


    </div>
  </section>

  <div class="flex justify-content-center rejectModal">
    <!-- <p-button  label="Show" /> -->
    <p-dialog [(visible)]="visible" [modal]="true" [style]="{ width: '500px', }">
      <div>
        <div class="d-flex justify-content-between align-items-start">
          <p class="fs18 fw600 font-black mb-3">
            Are you sure you want to reject the request
          </p>
          <img src="./assets/Images/x-close.svg" (click)="visible = false" alt="" />
        </div>
        <div class=" my-textarea">
          <textarea class="p-2 mb-3" name="rejectReason" id="rejectDescription" rows="8" cols="58"
            placeholder="Describe" [(ngModel)]="rejectTextarea"></textarea>
        </div>
        <div class="d-flex justify-content-end gap-3">
          <button class="secondary-btn" (click)="(visible = false) || (rejectTextarea='') ">
            Cancel
          </button>
          <button class="reject-button ml-3" pRipple (click)="handleReject(lastSegment)" [disabled]="!rejectTextarea">
            Reject
          </button>
        </div>
      </div>
    </p-dialog>
  </div>
  <p-toast />

</main>
<p-toast />
