import { Component } from '@angular/core';

@Component({
  selector: 'app-email-template-goal-setting',
  templateUrl: './email-template-goal-setting.component.html',
  styleUrls: ['./email-template-goal-setting.component.scss']
})
export class EmailTemplateGoalSettingComponent {

}
