import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
// import { environment } from '../../app/environments/environment';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class CandidateService {

  headers = new HttpHeaders().set('content-type', 'application/json').set('Access-Control-Allow-origin', '*').set('Cache-Control', 'no-cache')
        .set('Pragma', 'no-cache');
  public userData: any;

  constructor(private http: HttpClient) {
    let user = localStorage.getItem('userDetails');
    if (user != null) {
        // let userDetails = JSON.parse(user);
        // this.userData = userDetails;
        // let userId = userDetails.userId.toString();
        // this.headers = this.headers.append('userId', userId);

        this.userData = jwtDecode(user)
        let userId = this.userData.UserId
        this.headers = this.headers.append('userId', userId);
    }
}


  CreateApplicantPersonalDetails(data:any) {
    return this.http.post(`${environment.apiUrl}/api/Applicant/CreateApplicantPersonalDetails1`, data, { headers: this.headers })

  }

  CreateApplicantEducationDetails(data:any) {
    return this.http.post(`${environment.apiUrl}/api/Applicant/CreateApplicantEducationDetails`, data, { headers: this.headers })

}



ValidateApplicantPhoneNo(number:any) {
    return this.http.get(`${environment.apiUrl}/api/Applicant/ValidateApplicantPhoneNo?phoneNo=${number}`, { headers: this.headers });
}

ValidateApplicantEmailId(email:any) {
    return this.http.get(`${environment.apiUrl}/api/Applicant/ValidateApplicantEmailId?mailId=${email}`, { headers: this.headers });
}
createApplicantDocument(data:any) {
  return this.http.post(`${environment.apiUrl}/api/Applicant/createApplicantDocument`, data, { headers: this.headers })

}
selectDocumnentGet(id:any) {
  return this.http.get(`${environment.apiUrl}/api/Master/GetJobDocumentTypeDocumentNameDetails?jobId=${id}`, { headers: this.headers })
}
GetJobDocumentTypeDocumentNameDetails(val: any) {
  return this.http.get(`${environment.apiUrl}/api/Master/GetJobDocumentTypeDocumentNameDetails?jobId=${val}`, { headers: this.headers });
}

CreateApplicantImmigration(data:any) {
  return this.http.post(`${environment.apiUrl}/api/Applicant/CreateApplicantImmigration`, data, { headers: this.headers })

}
CreateApplicantWorkHistory(data:any) {
  return this.http.post(`${environment.apiUrl}/api/Applicant/CreateApplicantWorkHistory`, data, { headers: this.headers })

}
GetWorkPermitTypeByJobId(val: any) {
  return this.http.get(`${environment.apiUrl}/api/Applicant/GetWorkPermitTypeByJobId?id=${val}`, { headers: this.headers });
}
getCountriesnew() {
  return this.http.get(`${environment.apiUrl}/api/Applicant/GetCountriesPhp`, { headers: this.headers });
}
GetNationalityList() {
  return this.http.get(`${environment.apiUrl}/api/Master/GetNationalityList`, { headers: this.headers });
}
GetNationalityById(id:any) {
  return this.http.get(`${environment.apiUrl}/api/Master/GetNationalityById?id=${id}`, { headers: this.headers });
}

getCoucampus(status:any) {
  return this.http.get(`${environment.apiUrl}/api/Applicant/GetStatesPhp?CountryId=${status}`, { headers: this.headers });
}

getcities(val: any) {
    return this.http.get(`${environment.apiUrl}/api/Master/GetCitiesBasedOnCountryId?countryId=${val}`, { headers: this.headers });
}
getEthicReligionList() {
    return this.http.get(`${environment.apiUrl}/api/Applicant/GetEthicReligionListPhp`, { headers: this.headers });
}
GetIndustry() {
  return this.http.get(`${environment.apiUrl}/api/Master/GetIndustry`, { headers: this.headers });
}
GetDomain() {
  return this.http.get(`${environment.apiUrl}/api/Master/GetDomain`, { headers: this.headers });
}

//

GetUserlist1() {
  return this.http.get(`${environment.apiUrl}/api/User/GetRampoCodeDetails`, { headers: this.headers });
}

//
GetUserlistreportuserid1(isManagesettings = false) {
  return this.http.get(`${environment.apiUrl}/api/User/GetRampoCodeDetailsById?userId=${isManagesettings}`, { headers: this.headers });
}
GetUserlist() {
  return this.http.get(`${environment.apiUrl}/api/User/GetUserlist`, { headers: this.headers });
}

//
GetUserlistreportuserid(isManagesettings = false) {
  return this.http.get(`${environment.apiUrl}/api/User/GetUserlistreport?userId=${isManagesettings}`, { headers: this.headers });
}

getLevelOfEducation(isManagesettings = false) {
  return this.http.get(`${environment.apiUrl}/api/Master/GetEducationList?isManagesettings=${isManagesettings}`, { headers: this.headers });
}

getDegree(isManagesettings = false) {
  return this.http.get(`${environment.apiUrl}/api/Master/GetDegree?isManagesettings=${isManagesettings}`, { headers: this.headers })
}

GetYearsDetails() {
  return this.http.get(`${environment.apiUrl}/api/Master/GetYearsDetails`, { headers: this.headers });
}
getLanguages(isManagesettings = false) {
  //  return this.http.get(`${environment.apiUrl}/api/Master/GetLanguages`, { headers: this.headers });
  return this.http.get(`${environment.apiUrl}/api/Master/GetLanguages?isManagesettings=${isManagesettings}`, { headers: this.headers });
}
ChangeApplicantStatusSubmit(id: any) {
  return this.http.get(`${environment.apiUrl}/api/Applicant/ChangeApplicantStatusSubmit?applicantId=${id}`, { headers: this.headers });
}


GetCampusNames() {
  return this.http.get(`${environment.apiUrl}/api/Master/GetCampusNames`, { headers: this.headers });
}

GetExpereince() {
  return this.http.get(`${environment.apiUrl}/api/Master/GetExpereince`, { headers: this.headers });
}
}
