<main class="email_template_goal">
    <section class="performance">
        <div class="container_template">
            <div class="text-center d-flex align-items-center justify-content-center" style="gap: 16px;">
                <img src="./assets/img/GSG-logo.svg" alt="">
                <div>
                    <img src="./assets/img/smartlearn.svg" alt="">
                </div>
            </div>
            <div class="performance_mgmt bg-white">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center" style="gap: 10px;">
                        <img src="./assets/img/perform-star.svg" alt="">
                        <p class="fs16 fw600 texting_color">Career Portal</p>
                    </div>
                    <p class="fs16 fw600 texting_color">Registration</p>
                </div>
            </div>
            <div class="template_content bg-white mb-1">
                <p>Dear [Applicant's First Name],</p>
                <p>
                    

                    Thank you for submitting your application for the [Job Title] position at [Company Name]. We have successfully received your application, and our team will carefully review your qualifications.<br>
                                    
                    If your profile aligns with the role, one of our team members will reach out to you for the next steps in the hiring process.<br>
                                    
                    Here’s a summary of your application:<br>
                                    
                    Position Applied For: [Job Title]<br>
                    Application Submission Date: [Submission Date]<br>
                    If you have any questions or need to update your information, please feel free to contact us at [HR Contact Email] or call us at [HR Contact Phone Number].<br>
                                    
                    Thank you for your interest in joining our team at [Company Name]. We appreciate the time and effort you put into your application and will be in touch soon.<br>
                                    
                    Best regards,
                </p>

                <div class="text-center">
                    <button type="button" class="blue-btn"> View Appilcation Status</button>
                </div>
            </div>
            <div class="performance_mgmt bg-white mt-3">
                <div class="d-flex align-items-center justify-content-between">
                        <p class="fs16 fw600 texting_color">Do you still need our help?</p>
                    <p class="fs14 fw400 texting_color">Email us at <span class="fs14 fw500 texting_color">gsf.career@gmail.com</span></p>
                </div>
            </div>
        </div>
    </section>
</main>
